import React, { FC, memo, SVGProps } from 'react';

export const UploadIcon2: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    fill="none"
    className="w-4"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.71 15.74a.266.266 0 00-.42 0l-3.718 4.705a.264.264 0 00.21.429h2.453v8.048c0 .146.12.266.265.266h1.993c.146 0 .265-.12.265-.266v-8.045h2.46a.265.265 0 00.21-.428l-3.719-4.708z"
    ></path>
    <path
      fill="currentColor"
      d="M27.441 12.676c-1.52-4.011-5.395-6.864-9.934-6.864-4.54 0-8.414 2.85-9.935 6.86a6.643 6.643 0 00-4.947 6.422 6.638 6.638 0 006.637 6.64h1.332c.146 0 .265-.12.265-.265v-1.992a.267.267 0 00-.265-.266H9.262a4.093 4.093 0 01-2.955-1.252 4.115 4.115 0 01-1.159-3.008c.03-.877.33-1.7.87-2.394a4.16 4.16 0 012.195-1.45l1.258-.33.462-1.215A8.152 8.152 0 0112.858 9.8a8.04 8.04 0 014.649-1.467A8.04 8.04 0 0122.155 9.8a8.16 8.16 0 012.925 3.762l.459 1.212 1.255.332a4.136 4.136 0 013.058 3.988 4.094 4.094 0 01-1.206 2.912 4.087 4.087 0 01-2.908 1.205h-1.332a.267.267 0 00-.265.266v1.992c0 .146.12.265.265.265h1.332a6.638 6.638 0 006.637-6.64 6.641 6.641 0 00-4.934-6.418z"
    ></path>
  </svg>
));
