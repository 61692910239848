import React, { FC, memo, SVGProps } from 'react';

export const FilterIcon: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 12"
    className="w-4"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.748 2a2.66 2.66 0 012.573-2 2.66 2.66 0 012.574 2h1.412c.367 0 .665.296.665.667a.666.666 0 01-.665.666h-1.412a2.66 2.66 0 01-2.574 2 2.66 2.66 0 01-2.573-2H.693a.664.664 0 01-.665-.666C.028 2.298.325 2 .693 2h8.055zM2.105 8.667a2.66 2.66 0 012.573-2 2.66 2.66 0 012.574 2h8.055a.665.665 0 010 1.333H7.252a2.66 2.66 0 01-2.574 2 2.66 2.66 0 01-2.573-2H.693a.664.664 0 01-.665-.667c0-.368.298-.666.665-.666h1.412zm2.573 2A1.333 1.333 0 004.677 8 1.33 1.33 0 003.35 9.333a1.33 1.33 0 001.328 1.334zM11.322 4a1.33 1.33 0 001.328-1.333 1.33 1.33 0 00-1.328-1.334 1.33 1.33 0 00-1.329 1.334c0 .736.595 1.333 1.329 1.333z"
      clipRule="evenodd"
    ></path>
  </svg>
));
