import React, { FC, memo, SVGProps } from 'react';

export const CrossIcon3: FC<SVGProps<SVGSVGElement>> = memo((props) => (
  <svg
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-4"
    {...props}
  >
    <line
      x1="0.353553"
      y1="0.646447"
      x2="26.3536"
      y2="26.6464"
      stroke="currentColor"
    />
    <line
      x1="26.3536"
      y1="1.35355"
      x2="0.353553"
      y2="27.3536"
      stroke="currentColor"
    />
  </svg>
));
