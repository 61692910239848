import React, { FC, useEffect, useRef } from 'react';

type FadeInDivProps = {
  className?: string;
};

export const FadeInDiv: FC<FadeInDivProps> = ({ children, className }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // We do a timeout of length 0 to force the opacity change to be done
    // in the next event loop iteration so that an opacity animation
    // is guaranteed.
    setTimeout(() => {
      if (ref.current) {
        ref.current.style.opacity = '1';
      }
    }, 0);
  }, []);

  return (
    <div
      ref={ref}
      style={{
        opacity: '0',
        transition: 'opacity 300ms',
      }}
      className={className}
    >
      {children}
    </div>
  );
};
