import {
  IModelType,
  ModelProperties,
  resolveIdentifier,
  types as t,
} from 'mobx-state-tree';

// A regular MST reference throws an error if the model instance the reference
// corresponds to can't be found in the tree. This custom reference returs null
// until the model instance can be found in the tree, since we can't guarantee
// that the model is in the tree yet.
export function maybeNullReference<Props extends ModelProperties, Others>(
  model: IModelType<Props, Others>,
) {
  return t.maybeNull(
    t.reference(model, {
      get(id, parent) {
        if (parent === null) return null;

        return resolveIdentifier(model, parent, id) || (null as any);
      },
      set(thing) {
        return thing.id;
      },
    }),
  );
}
